.bounce-in-top {
    animation: bounce-in-top 4.1s ease-out both
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-12 8:47:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes bounce-in-top {
    0% {
        transform: translateY(-500px);
        animation-timing-function: ease-in;
        opacity: 0
    }

    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1
    }

    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in
    }

    72% {
        transform: translateY(0);
        animation-timing-function: ease-out
    }

    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in
    }

    90% {
        transform: translateY(0);
        animation-timing-function: ease-out
    }

    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in
    }

    100% {
        transform: translateY(0);
        animation-timing-function: ease-out
    }
}

.slide-in-left {
    animation: slide-in-left 3.1s cubic-bezier(.25, .46, .45, .94) both
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-12 9:5:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes slide-in-left {
    0% {
        transform: translateX(-1000px);
        opacity: 0
    }

    100% {
        transform: translateX(0);
        opacity: 1
    }
}

.wobble-hor-bottom {
    animation: wobble-hor-bottom 2s linear 3.5s infinite both
}

.wobble-hor-bottom:hover {
    animation: none
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-12 9:10:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@keyframes wobble-hor-bottom {

    0%,
    100% {
        transform: translateX(0);
        transform-origin: 50% 50%
    }

    15% {
        transform: translateX(-30px) rotate(-6deg)
    }

    30% {
        transform: translateX(15px) rotate(6deg)
    }

    45% {
        transform: translateX(-15px) rotate(-3.6deg)
    }

    60% {
        transform: translateX(9px) rotate(2.4deg)
    }

    75% {
        transform: translateX(-6px) rotate(-1.2deg)
    }
}

.rotate-infinitely {
    animation: infinite-rotation 10s linear infinite;
}

@keyframes infinite-rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.slide-right {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-18 4:45:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}

@keyframes slide-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(100px);
        transform: translateX(100px);
    }
}


.scale-in-ver-bottom {
    -webkit-animation: scale-in-ver-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-29 16:46:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

.scale-up-center {
    -webkit-animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-29 16:53:43
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.pulsate-bck {
    -webkit-animation: pulsate-bck 5s ease-in-out infinite both;
    animation: pulsate-bck 5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-8-30 15:17:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}