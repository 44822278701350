.register-with-us_image {
    width: 100%;
    position: absolute;
    bottom: 15%;
    right: 0;
    left: 0;
    z-index: 0;
}

.register-with-us_image-2 {
    width: 100%;
    position: absolute;
    bottom: 5%;
    right: 0;
    left: 0;
    z-index: 0;
}



@media only screen and (max-width: 425px) {
    .register-with-us_image-2 {
        left: 25%;
        bottom: 10%;
    }
}

.category-card-bg {
    background: rgba(255, 255, 255, 0);
    background: -webkit-linear-gradient(44deg, rgba(255, 255, 255, 0) 0%, #e17245 100%);
    background: linear-gradient(44deg, rgba(255, 255, 255, 0) 0%, #e17245 100%);
}

.category-card-bg:hover {
    cursor: pointer !important;
    background-color: #e17245 !important;
    color: white !important;
    transition: background-color .5s ease-out
}

.category-card-bg:hover > .underline {
    background-color: white !important;
    border: 'none' !important;
    transition: all .5s ease-out
}
