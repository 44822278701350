p, li {
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.65;
    color: #333333;
}

.entry-title {
    width: calc(100% - 60px);
    max-width: 1140px;
    padding: 60px 0;
    margin: 0 auto;
    border-bottom: 1px solid #e6e6e6;
    color: #045089;
}

.content-editor { 
    width: calc(100% - 30px);
    max-width: 1170px;
    margin: 0 auto;
    padding: 40px 15px;
}