.logo-checkout {
    text-align: center;
    animation: myAnim 1s ease-in-out 0s 1 normal forwards;
}

@keyframes myAnim {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}