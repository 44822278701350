#dishes, #restaurants {
    animation: entrance 1s ease 0s 1 normal forwards;
}

@keyframes entrance {
	0% {
		opacity: 0;
		transform: translateY(250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


@media only screen and (max-width: 599px) {

	.navbar-logo {
		width: 65px
	}
}